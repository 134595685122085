import * as React from 'react'
import styled from 'styled-components'

import { Icon } from '@ulule/owl-kit-components/dist/main.esm'
import { glyphs } from '@ulule/owl-kit-components/dist/next.esm'

const Wrapper = styled.div`
  flex: 0 0 auto;
  width: 180px;
  height: auto;
  background-color: #fff;
  border: 1px solid #bdbdbd;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 20px 10px;
`

const IconName = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: #111;
  margin-top: 16px;
`

type IconBlockProps = {
  collection?: glyphs.Collection
  glyph: string
  next?: boolean
}

export function IconBlock({ collection, glyph, next }: IconBlockProps): React.ReactElement<IconBlockProps> {
  let DisplayIcon = Icon

  if (next) {
    if (!collection) {
      throw 'please provide a collection'
    }
    DisplayIcon = (glyphs as any)[collection][glyph]
  }

  return (
    <Wrapper>
      {next ? <DisplayIcon size={32} /> : <Icon size={32} glyph={glyph} />}
      <IconName>{next ? `${glyph}` : `${glyph} (legacy)`}</IconName>
    </Wrapper>
  )
}
