import * as React from 'react'
import styled from 'styled-components'
import { LiveProvider } from 'react-live'

import { LiveCodeEditor, CodeEditorProps } from './CodeEditor'
import { CodePreview } from './CodePreview'

const PlaygroundPreview = styled(CodePreview)`
  margin-bottom: 0;
`

type PlaygroundProps = {
  defaultBackground?: 'transparent' | 'light' | 'dark'
  enableCopy?: boolean
  enablePreview?: boolean
  enableBackgroundChange?: boolean
  enableViewportChange?: boolean
  language?: CodeEditorProps['language']
  scope: { [s: string]: any }
  children: string
}

export function Playground({
  defaultBackground = 'transparent',
  enableCopy = true,
  enablePreview = true,
  enableBackgroundChange = true,
  enableViewportChange = true,
  language = 'jsx',
  scope,
  children,
}: PlaygroundProps): React.ReactElement<PlaygroundProps> {
  const [error, setError] = React.useState<string>()
  const [code, setCode] = React.useState(children)
  return (
    <LiveProvider code={code} scope={scope} language={language} transformCode={code => `<>${code}</>`}>
      {enablePreview && (
        <PlaygroundPreview
          defaultBackground={defaultBackground}
          enableBackgroundChange={enableBackgroundChange}
          enableViewportChange={enableViewportChange}
          error={error}
        />
      )}
      <LiveCodeEditor code={code} onChange={setCode} onError={setError} enableCopy={enableCopy} />
    </LiveProvider>
  )
}
/*
class Playground extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
    }
  }

  handleError = error => {
    this.setState({ error })
  }

  render() {
    return (
      <LiveProvider code={this.props.children} scope={this.props.scope || scope} mountStylesheet={false}>
        {this.props.enablePreview && (
          <PlaygroundPreview
            defaultBackground={this.props.defaultBackground}
            enableBackgroundChange={this.props.enableBackgroundChange}
            enableViewportChange={this.props.enableViewportChange}
            error={this.state.error}
          />
        )}
        <CodeEditor
          onChange={this.handleCodeChange}
          onError={this.handleError}
          code={this.props.children}
          enableCopy={this.props.enableCopy}
        />
      </LiveProvider>
    )
  }
}

Playground.defaultProps = {
  defaultBackground: 'transparent',
  language: 'jsx',
  enableCopy: true,
  enablePreview: true,
  enableBackgroundChange: true,
  enableViewportChange: true,
}

Playground.propTypes = {
  defaultBackground: PropTypes.oneOf(['transparent', 'light', 'dark']),
  enableCopy: PropTypes.bool,
  enablePreview: PropTypes.bool,
  enableBackgroundChange: PropTypes.bool,
  enableViewportChange: PropTypes.bool,
  language: PropTypes.string,
  code: PropTypes.string,
  scope: PropTypes.object,
  children: PropTypes.node.isRequired,
}

export default Playground
*/
