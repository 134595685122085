import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { IconBlock } from '../../../components/IconBlock';
import { Playground } from '../../../components/Playground';
import { SEO } from '../../../components/SEO';
import { Layout } from '../../../layouts/Layout';
import { glyphs } from '@ulule/owl-kit-components/dist/next.esm';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="Identity > Iconography > Glyphs" mdxType="SEO" />
    <h1>{`Glyphs`}</h1>
    <h2>{`Usage`}</h2>
    <p>{`These icons are available through the `}<inlineCode parentName="p">{`glyphs`}</inlineCode>{` namespace, and can be used as follows:`}</p>
    <Playground scope={{
      glyphs
    }} mdxType="Playground">{`
  <glyphs.fill.Heart />
`}</Playground>
    <h2>{`Main collections`}</h2>
    <h3>{`Fill`}</h3>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px'
    }}>
  {Object.keys(glyphs.fill).filter(name => name !== 'VideoPlay').map(name => {
        return <IconBlock collection="fill" glyph={name} next mdxType="IconBlock" />;
      })}
    </div>
    <h3>{`Stroke`}</h3>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px'
    }}>
  {Object.keys(glyphs.stroke).map(name => {
        return <IconBlock collection="stroke" glyph={name} next mdxType="IconBlock" />;
      })}
    </div>
    <h3>{`Two-toned`}</h3>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px'
    }}>
  {Object.keys(glyphs.twoToned).map(name => {
        return <IconBlock collection="twoToned" glyph={name} next mdxType="IconBlock" />;
      })}
    </div>
    <h2>{`Misc. collections`}</h2>
    <h3>{`Coffee`}</h3>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px'
    }}>
  {Object.keys(glyphs.coffee).map(name => {
        return <IconBlock collection="coffee" glyph={name} next mdxType="IconBlock" />;
      })}
    </div>
    <h3>{`Crowdfunding`}</h3>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px'
    }}>
  {Object.keys(glyphs.crowdfunding).filter(name => name !== 'ProgressEgg').map(name => {
        return <IconBlock collection="crowdfunding" glyph={name} next mdxType="IconBlock" />;
      })}
    </div>
    <h3>{`Currencies`}</h3>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px'
    }}>
  {Object.keys(glyphs.currencies).map(name => {
        return <IconBlock collection="currencies" glyph={name} next mdxType="IconBlock" />;
      })}
    </div>
    <h3>{`Payment methods`}</h3>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px'
    }}>
  {Object.keys(glyphs.paymentMethods).map(name => {
        return <IconBlock collection="paymentMethods" glyph={name} next mdxType="IconBlock" />;
      })}
    </div>
    <h3>{`Third-party`}</h3>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px'
    }}>
  {Object.keys(glyphs.thirdParty).map(name => {
        return <IconBlock collection="thirdParty" glyph={name} next mdxType="IconBlock" />;
      })}
    </div>
    <br />
    <hr />
    <h1>{`Legacy Icons`}</h1>
    <p>{`These icons have yet to be migrated to the new icon system:`}</p>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px'
    }}>
  <IconBlock glyph="at" mdxType="IconBlock" />
  {
        /* <IconBlock glyph="check-circle-line" /> */
      }
  <IconBlock glyph="refresh" mdxType="IconBlock" />
  {
        /* <IconBlock glyph="url" /> */
      }
  {
        /* <IconBlock glyph="bulb" /> */
      }
  <IconBlock glyph="stop" mdxType="IconBlock" />
  <IconBlock glyph="anchor" mdxType="IconBlock" />
  <IconBlock glyph="burger-menu" mdxType="IconBlock" />
  {
        /* <IconBlock glyph="close-circle" /> */
      }
  <IconBlock glyph="check-smaller-circle" mdxType="IconBlock" />
  {
        /* <IconBlock glyph="arrow-right" /> */
      }
  {
        /* <IconBlock glyph="plus" /> */
      }
  <IconBlock glyph="minus" mdxType="IconBlock" />
  <IconBlock glyph="caret-right-light" mdxType="IconBlock" />
  <IconBlock glyph="caret-left-light" mdxType="IconBlock" />
  {
        /* <IconBlock glyph="read" /> */
      }
  {
        /* <IconBlock glyph="letter" /> */
      }
  {
        /* <IconBlock glyph="check" /> */
      }
  {
        /* <IconBlock glyph="social" /> */
      }
  <IconBlock glyph="arrow-up-right" mdxType="IconBlock" />
  <IconBlock glyph="select-all" mdxType="IconBlock" />
  <IconBlock glyph="paperclip" mdxType="IconBlock" />
  <IconBlock glyph="cogwheel" mdxType="IconBlock" />
  {
        /* <IconBlock glyph="social-youtube" /> */
      }
  {
        /* <IconBlock glyph="video-play" /> */
      }
  <IconBlock glyph="rounded-plus" mdxType="IconBlock" />
  <IconBlock glyph="trash" mdxType="IconBlock" />
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      